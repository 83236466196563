<template>
    <div class="info">
      <top-bar :title="'指南详情'" :left="true"></top-bar>
      <div class="infoBox">
        <div class="title">
          <p>{{detailData.title}}</p>
          <span>{{detailData.createTime}}</span>
        </div>
        <van-divider :hairline="false" />
        <div class="content" v-html="detailData.content">
        </div>
      </div>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import { mapState } from 'vuex'
export default {
  data () {
    return {
      detailData: {}
    }
  },
  computed:{...mapState(['guideId'])},
  components :{
    topBar
  },
  methods: {
    getDetail () {
      console.log(this.guideId);
      this.$http({
        url: this.$http.adornUrl('/wxapp/guide/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.guideId
        })
      }).then(({data})=> {
          if (data.code == 0) {
            this.detailData = data.guide
          this.$toast.clear()
          } else {
            console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
          }
        })
    }
  },
  created () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDetail()
  }
}
</script>
<style>
  a {
  color: rgb(4, 50, 255);
}
</style>
<style lang="scss" scoped>
.info { 
  .infoBox {
    border-radius: 8px;
    background-color: #fff;
    padding: 30px;
    .title {
      p {
        font-size: 36px;
        margin: 10px 0 20px;
      }
      span {
        color: #999;
      }
    }
    .content {
      font-size: 30px;
    }
  }
}
</style>
